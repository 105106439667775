<template>
  <div class="max-w-4xl bg-white mx-auto rounded-md p-4">
    <div class="flex flex-col gap-4">
      <div class="bg-brand p-4 rounded-md">
        <span class="text-2xl text-white">Appointment List</span>
      </div>
      <div
        @click="$router.go(-1)"
        class="text-brand rounded-md py-1.5 px-2 cursor-pointer"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          class="h-6 w-6"
          fill="none"
          viewBox="0 0 24 24"
          stroke="currentColor"
          stroke-width="2"
        >
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M7 16l-4-4m0 0l4-4m-4 4h18"
          />
        </svg>
      </div>
      <table class="w-full mx-auto">
        <thead class="border">
          <tr>
            <th class="border-r p-1">SL</th>
            <th class="border-r p-1">Patient Name</th>
            <th class="border-r p-1">Serial</th>
            <th class="border-r p-1">Type</th>
            <th class="border-r b-1">Question</th>
            <th class="border-r p-1">Feedback</th>
          </tr>
        </thead>
        <tbody class="border">
          <tr
            v-for="(appointment, index) in appointments.appointments"
            :key="index"
            class="hover:bg-gray-100"
          >
            <td class="border-r border-b p-1">{{ appointment.id }}</td>
            <td class="border-r border-b p-1">{{ appointment.patientName }}</td>
            <td class="border-r border-b p-1">{{ appointment.serial }}</td>
            <td class="border-r border-b p-1">{{ appointment.type }}</td>
            <td
              class="border-r border-b p-1 print:max-w-full"
              style="width: 150px"
            >
              <router-link
                :to="{
                  name: 'questionPrint',
                  params: { appointmentId: appointment.id },
                }"
                class="bg-brand text-white py-2 p-2 rounded-md hover:shadow-md"
                >Question List</router-link
              >
            </td>
            <td class="border-r border-b p-2">
              <div
                v-if="appointment.mentor"
                class="text-brand text-center flex justify-center items-center"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="h-6 w-6"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  stroke-width="2"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M5 13l4 4L19 7"
                  />
                </svg>
              </div>
              <div
                v-else
                @click="feedbackAssign(appointment.id)"
                class="cursor-pointer hover:underline hover:text-brand text-blue-400"
              >
                Feedback
              </div>

              <div
                class="relative z-10"
                aria-labelledby="modal-title"
                role="dialog"
                v-if="ariaModal"
              >
                <div
                  class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
                ></div>
                <div class="fixed z-10 inset-0 overflow-y-auto">
                  <div
                    class="flex items-end sm:items-center justify-center min-h-full p-4 text-center sm:p-0"
                  >
                    <form
                      @submit.prevent="feedbackDataStore()"
                      class="relative bg-white rounded-lg text-left overflow-hidden shadow-xl transform transition-all"
                    >
                      <div class="text-right">
                        <button
                          type="submit"
                          class="mt-2 mr-2 rounded-md shadow-sm text-red-500"
                          @click="ariaModal = false"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="h-5 w-5"
                            viewBox="0 0 20 20"
                            fill="currentColor"
                          >
                            <path
                              fill-rule="evenodd"
                              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                              clip-rule="evenodd"
                            />
                          </svg>
                        </button>
                      </div>

                      <div class="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
                        <div
                          class="flex sm:items-start md:justify-center md:items-center"
                        >
                          <div
                            class="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left"
                          >
                            <h3
                              class="text-2xl text-gray-900 font-mono text-center"
                              id="modal-title"
                            >
                              Feedback
                            </h3>
                            <div class="mt-2">
                              <div
                                v-for="(question, key) in feedbacks.questions"
                                :key="key"
                                class="flex flex-col justify-center items-center mx-auto"
                              >
                                <div>{{ question }}</div>
                                <Rating
                                  :numberOfStar="rating.length"
                                  v-model="rating[key]"
                                  required
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="w-full">
                          <InputTextArea
                            v-model="form.note"
                            class="w-full border focus:outline-none p-2"
                            placeholder="Anything else to mention .... "
                          />
                        </div>
                      </div>
                      <div
                        class="bg-gray-50 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse"
                      >
                        <button
                          type="submit"
                          class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-600 text-base text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm"
                        >
                          Submit
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Rating from "./Rating.vue";
import InputTextArea from "../../../../components/textarea.vue";

export default {
  data() {
    return {
      rating: [],
      form: {
        appointment_id: "",
        fq_id: "",
        note: "",
        user_id: "",
      },
      ariaModal: false,
    };
  },
  components: {
    Rating,
    InputTextArea,
  },

  created() {
    this.$store.dispatch("mentor_appointment/mentorAppointment", {
      schedule_id: this.scheduleId,
    });

    this.$store.dispatch("schedule/getPreviousHistory");
  },

  //   watch: {
  //     "$store.state.feedback.patientQuestions"(data) {
  //       console.log("watch1", data.questions.id);
  //       this.form.appointment_id = data.appointments.id || "";
  //       this.form.user_id = data.user_id || "";
  //       this.form.fq_id = data.questions.id || "";
  //     },
  //   },

  computed: {
    appointments() {
      return this.$store.state.mentor_appointment.appointments;
    },
    scheduleId() {
      return this.$route.params.scheduleId;
    },
    feedbacks() {
      const question =
        this.$store.state.feedback.patientQuestions.questions || "";
      return question || "";
    },
  },
  methods: {
    feedbackAssign(data) {
      this.ariaModal = true;
      this.rating = [];
      this.form.note = "";
      this.form.appointment_id = data;
      this.$store.dispatch("feedback/patientFeedbackQuestion", {
        appointment_id: data,
      });
    },

    feedbackDataStore() {
      // alert(this.ariaModal = false)
      //   console.log("this", this.feedbacks.id);
      //   console.log("this", this.$store.getters["auth/user"].id || "");
      //   console.log("this", this.form.note);
      // console.log("this", this.rating);
      this.$store
        .dispatch("feedback/feedbackSubmit", {
          form_data: {
            appointment_id: this.form.appointment_id,
            fq_id: this.feedbacks.id,
            note: this.form.note,
            user_id: this.$store.getters["auth/user"].id || "",
          },
          rating: this.rating,
        })
        .finally(() => {
          this.ariaModal = false;
          this.$store.dispatch("mentor_appointment/mentorAppointment", {
            schedule_id: this.scheduleId,
          });
        });
    },
  },
};
</script>

<style></style>
